<template lang="pug">
boxWrapper
  titleComponent(title="Datos nutricionales")
  .group-ingredients
    .head
      .name Nutriente
      .grams Valor
    .macro.macro-header.default
      .name Calorias
      .grams {{ nutrients.NET_CALORIES ?? nutrients.ENERC_KCAL ?? '--' }}kcal
    .macro.macro-header.red
      .name Total carbs
      .grams {{ nutrients.CHOCDF ?? '--' }}g
    .sub-macro
      .name - Net Carbs
      .grams {{ nutrients.NET_CARBS ?? '--' }}g
    .sub-macro
      .name - Fiber
      .grams {{ nutrients.FIBTG ?? '--' }}g
    .sub-macro
      .name - Sugar
      .grams {{ nutrients.SUGAR ?? '--' }}g
    .macro.macro-header.blue
      .name Proteinas
      .grams {{ nutrients.PROCNT ?? '--' }}g
    .macro.macro-header.yellow
      .name Grasas
      .grams {{ nutrients.FAT ?? '--' }}g
    .sub-macro
      .name - Monounsat. Fat
      .grams {{ nutrients.FAMS ?? '--' }}g
    .sub-macro
      .name - Polyunsat. Fat
      .grams {{ nutrients.FAPU ?? '--' }}g
    .sub-macro
      .name - Saturated Fat
      .grams {{ nutrients.FASAT ?? '--' }}g
</template>
<script>
import titleComponent from "./title-component.vue";
import boxWrapper from "./box-wrapper.vue";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "nutrition-component",
  components: {
    titleComponent,
    boxWrapper,
  },
  setup() {
    const namesMacros = {
      NET_CALORIES: "Calories",
      NET_CARBS: "Net Carbs",
      CHOCDF: "Total Carbs",
      FIBTG: "Fiber",
      SUGAR: "Sugar",
      SUGAL_ALCOHOLS: "Sugat Alcohols",
      PROCNT: "Protein",
      FAT: "Fat",
      FAMS: "Monounsat. Fat",
      FAPU: "Polyunsat. Fat",
      FASAT: "Satured Fat",
      CHOLE: "Cholesterol",
      VITA_RAE: "Vitamin A",
      VITC: "Vitamin C",
      VITD: "Vitamin D",
      VITB12: "Vitamin B12",
      VITB6: "Vitamin B6",
      VITK: "Vitamin K",
      ZN: "Zinc",
    };

    const store = useStore();
    const nutrients = computed(() => {
      const nutrients = store.getters["menuReceiptStore/nutrients"];
      const macrosInNutrientes = {};
      for (const macro in nutrients) {
        macrosInNutrientes[macro] = nutrients[macro].toFixed(2);
      }
      return macrosInNutrientes;
    });
    return {
      nutrients,
    };
  },
};
</script>
<style lang="scss" scoped>
.group-ingredients {
  @apply my-4;

  .head {
    @apply px-2 py-1 flex items-center justify-between text-sm border-b font-medium mb-2;
  }

  .macro {
    @apply px-2 py-1 flex items-center justify-between text-sm rounded-md;
    &.macro-header {
      @apply my-1 font-medium;
    }
    &.default {
      @apply bg-gray-100;
    }
    &.red {
      @apply bg-red-100 text-red-500;
    }
    &.blue {
      @apply bg-blue-100 text-blue-600;
    }
    &.yellow {
      @apply bg-yellow-100 text-yellow-600;
    }
  }
  .sub-macro {
    @apply pl-4 pr-2 py-1 flex items-center justify-between text-sm;
    & + .sub-macro {
      @apply border-t border-gray-100 border-dashed;
    }
  }
}
</style>